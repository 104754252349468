.global-header {
  display: block;

  .site-name-block {
    @mixin block-width-limit;

    display: flex;
    justify-content: space-between;
    padding: 1rem;

    /*
    @supports (display: grid) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    */
  }

  .site-name {
    align-self: center;
    margin: 0;
    font-size: 1.2rem;
  }

  .site-name-link {
    @mixin underline-when-hover;

    color: var(--key-color-one);
    text-decoration: none;
  }

  .site-tagline {
    align-self: center;
    margin: 0;
    font-size: 0.8rem;
    text-align: right;
  }
}
