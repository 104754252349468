.itinerary-list {
  padding-left: 0;
  margin: 0;
  color: #000;
  list-style: none;

  .list-item-itinerary {
    padding-top: 0.75em;
  }

  .list-item-itinerary:first-child {
    padding-top: 0;
  }

  .itinerary-time {
    display: block;
    font-size: 0.8em;
    line-height: 1;
    color: #555;
  }

  @mixin screen-medium {
    .list-item-itinerary {
      padding-top: 0;
    }

    .itinerary-time {
      display: inline-block;
      width: 10em;
    }
  }
}
