.hero-block {
  position: relative;
  width: calc(100% + 2rem);
  margin-right: -1rem;
  margin-left: -1rem;

  .hero-image-block {
    --min-height: calc(500 / 828 * 100vw);

    position: relative;
    width: 100%;
    min-height: var(--min-height);
    overflow: hidden;
  }

  .hero-image-block > img,
  .hero-image-block > picture {
    display: block;
    width: 100%;
  }

  .hero-image-block > picture img {
    display: block;
    width: 100%;
  }

  .hero-image-block > picture .js-image-placeholder-thumb {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  .hero-text-block {
    color: #fff;
    background: var(--key-color-three);
  }

  .hero-title {
    padding: 0.5rem 0;
    margin: 0 1rem;
  }

  .hero-summary {
    @mixin block-width-limit;

    padding: 0.25rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  & + * {
    margin-top: 6rem;
  }

  @mixin screen-medium {
    /*
    --min-height-wide:calc(400 / 939 * 100vw);
    */

    .hero-image-block {
      /* max-height: calc(817 / 1920 * 100vw); */
      --min-height-wide: calc(395 / 939 * 100vw);

      height: var(--min-height-wide);
      min-height: 0;
    }

    .hero-title {
      @mixin block-width-limit;
    }
  }
}

.home-hero-block {
  --min-height: calc(500 / 828 * 100vw);

  position: relative;
  width: calc(100% + 2rem);
  min-height: var(--min-height);
  margin-right: -1rem;
  margin-left: -1rem;
  background: #000;

  /* min-height: calc(399 / 647 * 100vw); */

  /* 画像の縦横比から min-height を計算する */

  .hero-image-block {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
  }

  .hero-image-block > img,
  .hero-image-block > picture {
    display: block;
    width: 100%;
  }

  .hero-image-block > picture img {
    display: block;
    width: 100%;
  }

  .hero-image-block > picture .js-image-placeholder-thumb {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  .hero-text-block {
    @mixin block-width-limit;

    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: var(--min-height);
    color: #fff;

    /* min-height: calc(399 / 647 * 100vw); */

    /* 画像の縦横比から min-height を計算する */
  }

  .hero-title {
    margin: 1rem 1rem 0;
  }

  .hero-summary {
    margin: 1em 1rem 1rem;
  }

  @mixin screen-medium {
    --min-height-wide: calc(395 / 939 * 100vw);

    min-height: var(--min-height-wide);

    .hero-image-block {
      min-height: var(--min-height-wide);
    }

    .hero-text-block {
      min-height: var(--min-height-wide);
    }
  }
}
