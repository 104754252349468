.itinerary-table {
  display: block;
  width: 100%;
  margin-top: 6rem;

  caption {
    display: block;
    font-size: 1.2rem;
  }

  tbody {
    display: block;
    width: 100%;
    margin-top: 1em;
  }

  & tr {
    display: block;
    width: 100%;
    background: var(--bg-color-zebra-one);
  }

  & tr:nth-child(2n) {
    background: var(--bg-color-zebra-two);
  }

  th {
    display: block;
    padding: 1em 0.5em;
    line-height: 1.7;
    border-bottom: dashed 1px #ccc;

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  td {
    display: block;
    padding: 1em 0.5em;
    line-height: 1.7;

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  @mixin screen-medium {
    display: table;
    width: 100%;
    max-width: 58rem;
    margin-right: auto;
    margin-left: auto;
    border-collapse: collapse;

    caption {
      display: table-caption;
      margin-bottom: 1em;
    }

    tbody {
      display: table-row-group;
    }

    & tr {
      display: table-row;
    }

    /*
    & tr:nth-child(2n) {
    }
    */

    th {
      display: table-cell;
      vertical-align: top;
      border: none;
    }

    td {
      display: table-cell;
      padding: 1em 0.5em 1em;

      & > *:first-child {
        margin-top: 0;
      }
    }
  }
}
