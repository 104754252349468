.grid-menu-list {
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 1.5rem;
  grid-column-gap: 0.5rem;
  justify-content: space-between;
  max-width: 58rem;
  padding: 0;
  margin: 3rem auto 0;
  list-style: none;

  @supports (display:grid) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-menu-list-item {
    position: relative;
    width: calc(50% - 0.5rem);
    margin-top: 1rem;

    @supports (display:grid) {
      width: 100%;
      margin-top: 0;
    }
  }

  .grid-menu-list-anchor {
    box-sizing: border-box;
    display: block;
    height: 100%;
    padding: 0.5rem;
    text-decoration: none;
    background: var(--bg-color-alt);

    @supports (display:grid) {
      display: grid;
      grid-template-rows: min-content min-content;
    }
  }

  .grid-menu-list-anchor:hover {
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }

  .grid-menu-title-block {
    position: relative;
    padding: 0 0.25em 0 1.25rem;
    font-size: 1.2em;
    color: #fff;
    background: var(--key-color-three);
  }

  .grid-menu-title-block::before {
    position: absolute;
    margin-left: -0.75em;
    content: '\0bb';
  }

  .grid-menu-image-block {
    & img,
    & picture > img {
      display: block;
      width: 100%;
    }
  }

  .grid-menu-description-block {
    font-size: 0.8em;
    color: #000;

    & > *:last-child {
      margin-bottom: 0;
    }
  }
}
