.input-field {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  padding: 0.5em 1em;

  /* min-width: 15em; */
  margin-top: 0.5em;
  font-family: sans-serif;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25em;

  &:focus {
    border: 1px solid #666;
  }
}
