.image-block {
  @mixin block-width-limit;

  margin-top: 3rem;
  overflow: hidden;

  & > img,
  & > picture {
    width: 100%;
  }

  & > picture img,
  & > picture source {
    width: 100%;
  }
}
