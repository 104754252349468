.contact-block {
  box-sizing: border-box;
  padding: 2rem 1rem;
  margin: 3rem -1rem 0;
  color: #000;
  background: var(--bg-color-alt);

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
