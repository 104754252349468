:root {
  --key-color-one: #c14b28;
  --key-color-two: #29c299;
  --key-color-three: #f58d1b;
  --base-text-color: #222;
  --bg-color-alt: #f2f2f2;
  --bg-color-zebra-one: #eee1cc;
  --bg-color-zebra-two: #f9f2ec;
  --block-max-width: 50rem;
}

@define-mixin screen-medium {
  @media (min-width: 768px) {
    @mixin-content;
  }
}

@define-mixin side-margin {
  margin-right: 1rem;
  margin-left: 1rem;
}

@define-mixin heading {
  margin: 0;
  font-weight: normal;
  color: var(--key-color-one);
}

@define-mixin body-text {
  line-height: 1.7;
}

@define-mixin block-width-limit {
  max-width: var(--block-max-width);
  margin-right: auto;
  margin-left: auto;
}

@define-mixin underline-when-hover {
  &:hover {
    text-decoration: underline;
  }
}
