.global-footer {
  margin: 5rem 0 2rem;

  .footer-copyright {
    font-size: 0.9rem;
    text-align: center;
  }

  .footer-menu-list {
    padding: 1em 0;
    font-size: 0.9rem;
    background: var(--bg-color-alt);
  }

  .footer-menu-list-item {
    display: block;
    margin: 0 1rem;
  }

  @mixin screen-medium {
    .footer-menu-list {
      display: block;
      text-align: center;
    }

    .footer-menu-list-item {
      display: inline-block;
      padding: 0 0.5em;
      margin: 0;
    }
  }
}
