.global-navigation {
  color: #fff;
  background: var(--key-color-one);

  .menu-button {
    width: 100%;
    padding: 0.25rem 1rem;
    margin: 0;
    font-size: 1rem;
    color: #fff;
    text-align: left;
    background-color: transparent;
    border: 0;
    appearance: none;

    &:hover {
      color: var(--key-color-one);
      background: #f2f2f2;
    }
  }

  .menu-list {
    position: relative;
    display: block;
    height: auto;
    padding: 0;
    margin: 0;
    list-style: none;
    border-bottom: 1px solid #c68b78;
  }

  html.js & .menu-list.close {
    display: none;
  }

  .menu-link {
    display: block;
    padding: 0 1rem;
    color: #fff;
    text-decoration: none;

    &::before {
      content: '\0bb  ';
    }

    &:hover,
    &:active {
      color: var(--key-color-one);
      background: #f2f2f2;
    }
  }

  @mixin screen-medium {
    .menu-button {
      display: none;
    }

    .menu-list {
      @mixin block-width-limit;

      display: flex;
      border-bottom: none;
    }

    html.js & .menu-list.close {
      display: flex;
    }

    .menu-list-item {
      position: relative;
      width: calc(100% / 5);
    }

    @supports (display:grid) {
      .menu-list {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        border-bottom: none;
      }

      html.js & .menu-list.close {
        display: grid;
      }

      .menu-list-item {
        width: auto;
      }
    }

    .menu-link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-align: center;

      &::before {
        content: normal;
      }
    }
  }
}
