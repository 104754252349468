.button-primary {
  position: relative;
  display: inline-block;
  min-width: 12rem;
  padding: 0.75em 1em;
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
  background: var(--key-color-two);
  border: none;
  border-radius: 3px;
  box-shadow: 0 2px 1px #268a66, 0 0 5px rgba(0, 0, 0, 0.25);

  &:hover,
  &:focus {
    color: #fff;
    background: #1b8267;
  }
}

a.button-primary {
  color: #fff;
}

a.button-primary:visited {
  color: #fff;
}

.button-primary:disabled {
  color: #222;
  background: #ccc;

  &:hover,
  &:focus {
    color: #666;
    background: #f0f0f0;
    box-shadow: 0 2px 1px #ccc, 0 0 5px rgba(0, 0, 0, 0.25);
  }
}
