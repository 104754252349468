.image-block--strong {
  width: calc(100% + 2rem);
  margin-top: 3rem;
  margin-right: -1rem;
  margin-left: -1rem;
  overflow: hidden;

  & > img,
  & > picture {
    width: 100%;
  }

  & > picture img,
  & > picture source {
    width: 100%;
  }
}
