.input-textarea {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  min-height: 10em;
  padding: 0.5em 1em;
  margin-top: 0.5em;
  font-family: sans-serif;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25em;
}
