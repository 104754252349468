.contact-info-list {
  max-width: 30em;
  margin: 1rem auto 0;

  dt {
    padding: 0 0.25em;
    margin: 1em 0 0;
  }

  dd {
    padding: 0 0.25em;
    margin: 0;
    font-size: 1.2rem;
  }

  @supports (display: grid) {
    display: grid;
    grid-gap: 0;
    align-items: center;

    dt {
      grid-column: 1;
      margin: 0;
      text-align: right;
    }

    dd {
      grid-column: 2;
      text-align: left;
    }
  }
}
