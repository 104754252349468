.voice-owner {
  @mixin block-width-limit;

  box-sizing: border-box;
  padding: 1rem;
  margin-top: 3rem;
  border: dotted 1px #e6e6e6;

  .voice-owner-picture {
    width: 100px;
    padding-right: 1rem;
  }

  .voice-owner-picture img {
    width: 100%;
    border-radius: 50%;
  }

  .voice-owner-name {
    font-size: 0.9rem;
  }

  @supports (display:grid) {
    display: grid;
    grid-template-columns: 8em 1fr;
    align-items: center;

    .voice-owner-picture {
      width: auto;
    }
  }
}
