.input-guide-status + * {
  margin-top: 0;
}

.input-guide-status::after {
  position: absolute;
  top: 1.15rem;
  left: 0;
  display: inline-block;
  width: 1em;
  height: 1em;
  padding: 0.1em;
  margin-right: 0.5em;
  line-height: 1;
  content: '　';
  border: 1px dotted #aaa;

  .modified & {
    color: #33aa5f;
    content: '✓';
    border-color: #33aa5f;
  }

  .modified.invalid & {
    color: #f00;
    content: '！';
    border-color: #f00;
  }
}
