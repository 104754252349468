.input-block {
  @mixin block-width-limit;

  position: relative;
  padding: 1rem 0 1rem 2rem;
  border-top: 1px dotted #ccc;

  & :last-child {
    margin-bottom: 0;
  }
}
