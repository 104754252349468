.image-block--small {
  max-width: 1200px;
  margin-top: 2rem; /* 小要素でmargin top あり */
  margin-right: auto;
  margin-left: auto;

  &::after {
    /* clearfix */
    display: block;
    clear: both;
    content: '';
  }

  & > picture,
  & > img {
    display: block;
    float: left;
    width: calc(50% - 0.5rem);
    margin-top: 1rem;
  }

  & > picture img,
  & > picture source {
    width: 100%;
  }

  & > img:nth-child(2n+1),
  & > picture:nth-child(2n+1) {
    margin-right: 1rem;
  }

  /* html:not(.ie11) & { */
  @supports (display:grid) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    margin-top: 3rem;

    &::after {
      display: none;
    }

    & > img,
    & > picture {
      float: none;
      width: 100%;
      margin: 0;
    }

    & > img:nth-child(2n+1),
    & > picture:nth-child(2n+1) {
      margin: 0;
    }
  }

  /**
   * itinerary-list の下
   */
  .itinerary-list + & {
    margin-top: 1rem;
  }
}
