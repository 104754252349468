html,
body {
  font-family: sans-serif;
}

body {
  margin: 0;
}

main {
  @mixin side-margin;

  /* margin: 0 1rem 3rem;; */
}

p,
li {
  @mixin body-text;
}

a {
  color: #4f73e4;
}

a:visited {
  color: #a84fe4;
}

a:focus,
a:active {
  color: #1c41b6;
  text-decoration: none;
}

a:hover {
  color: #1c41b6;
  text-decoration: none;
}

/* external site */
a[href^="http"]::after,
a[href^="//"]::after {
  /*
  content:  "[external]";
*/
  display: inline-block;
  font-size: 0.8em;
  line-height: 1;
  content: "↗";
  background: #fff;
}

.js-image-placeholder-thumb {
  filter: blur(20px);
}

.js-image-loaded {
  filter: blur(0);
  transition: filter 0.25s ease-out;
}
