.list-item-note {
  padding-left: 1.25em;
  list-style: none;

  &::before {
    position: absolute;
    margin-left: -1.25em;
    content: '※';
  }
}
